export const menuItems = [
  {
    title: "Home",
    url: "/",
  },
  {
    title: "Industries",
    url: "/industries",
  },
  {
    title: "About",
    url: "/about",
  },
  {
    title: "Features",
    url: "/features",
  },
  {
    title: "Why CloudyFy Tv?",
    url: "/why-cloudyfy-tv",
  },
  {
    title: "Hardware",
    url: "/hardware",
  },
  // {
  //   title: "Why CloudyFy Tv?",
  //   url: "/why-cloudyfy-tv",
  // },
  {
    title: "Blog",
    url: "/blog",
  },
  {
    title: "Contact",
    url: "/contact",
  },
  // {
  //   title: "Pricing",
  //   url: "/pricing",
  // },
];
