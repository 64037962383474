import React from "react";
import Photo from "./Components/Photo";

class Industries extends React.Component {
  render() {
    return (
      <section className="section Industries-section">
        <div className="container">
          <div className="inner_box_content">
            <h2>Industries We Serve<span></span></h2>
            <p>Our digital signage software is useful for all the core industry sectors, from healthcare to hospitality and retail to real estate. </p>
          </div>
          <div className="Industries_block">
            <Photo />
          </div>
        </div>
      </section>
    )
  }
}

export default Industries;
