import React, { useState } from "react";
import ModalVideo from "react-modal-video";
import { Link } from "react-router-dom";
import video_play from "../../../../../assets/Images/video_play.svg";

const Videoplay = () => {
  const [isOpen, setOpen] = useState(false);
  return (
    <React.Fragment>
      <button className="video_play_button" onClick={() => {
        setOpen(true)
        document.body.classList.add(["video_open"])
      }}>
        <Link to="/" title="Dynamic video play icon for multimedia content" className="youtube play-btn">
          <img src={video_play} alt="Video Play Icon" title="Dynamic video play icon for multimedia content" />
        </Link>
      </button>
      
      <ModalVideo
        channel="youtube"
        isOpen={isOpen}
        videoId="L61p2uyiMSo"
        onClose={() => {
          setOpen(false)
          document.body.classList.remove(["video_open"])
        }}
      />
    </React.Fragment>
  );
};
export default Videoplay;
