import React from "react";
import Slider from "react-slick";
import productsData from "./Productsdata";
import ScrollAnimation from 'react-animate-on-scroll';
import { Link } from "react-router-dom";



function Slidersolution() {
  var settings = {
    dots: true,
    centerMode: true,
    infinite: true,
    speed: 500,
    arrows: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    focusOnSelect: true,
    centerPadding: '0',

    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: false,
        }
      }
    ]
  };

  return (
    <Slider {...settings}>
      {productsData.map((Productsdata) => {
        return (
          <React.Fragment key={Productsdata.id}>
            <ScrollAnimation delay={1}
              duration={1} animateIn="fadeInUp" animateOnce={true}>
              <div className="slider_img">
                <img src={Productsdata.img} alt={Productsdata.imagealt} title={Productsdata.imagetitle} />
              </div>

            </ScrollAnimation>
            {/* <p>{Productsdata.title}</p> */}
            <p><Link to={Productsdata.url}>{Productsdata.title}</Link></p>
          </React.Fragment>
        );
      })}
    </Slider>
  );
}

export default Slidersolution;
