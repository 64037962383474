import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";

import SliderData from "./Sliderdata";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {} from "@fortawesome/fontawesome-free-solid";

import ScrollAnimation from 'react-animate-on-scroll';


export default function SimpleSlider() {
  var settings = {
    dots: false,
    arrows: false,
    autoplay: true,
    infinite: false,
    speed: 500,
    cssEase:"linear",
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  useEffect(() => {
    document.body.classList = ["Home"];
  }, []);
  return (
      <Slider {...settings} className="homeHeroBanner">
        {SliderData.map((Sliderdata,index) => {
          return (
              <div className="banner" key={Sliderdata.id}>
             
                <div className="banner_text">
                <ScrollAnimation delay={3}
                 animateIn="fadeInLeft" duration={1} animateOnce={true}>
                  <div className="content">
                    <span>{Sliderdata.title}</span>
                    {index===0?<h1>{Sliderdata.heading}</h1>:<h2>{Sliderdata.heading}</h2>}
                    <p>{Sliderdata.content}</p>
                  </div>
                  <div className="btn btn-border">
                    <Link to="/why-cloudyfy-tv" className="primary_button" title="Read More">
                      {Sliderdata["buttonprimary"]}
                      <FontAwesomeIcon icon="fa-solid fa-chevron-right" />
                    </Link>
                    <Link to="/contact" className="secoundary_button" title="Contact Us">
                      {Sliderdata["buttonsecondary"]}
                      <FontAwesomeIcon icon="fa-solid fa-chevron-right" />
                    </Link>
                  </div>
                  </ScrollAnimation>
                </div>
       
                <div className="banner_img">
                <ScrollAnimation delay={3}
                duration={1} animateIn="fadeInRight" animateOnce={true}>
                  <img src={Sliderdata.image} alt={Sliderdata?.imagealt} title={Sliderdata?.imagetitle} />
                  </ScrollAnimation>

                </div>
              </div>
          );
        })}
      </Slider>
  );
}
