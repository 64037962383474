import React from "react";
import { Link } from "react-router-dom";
import { Footerdata } from "./Footerdata";
import { useLocation } from "react-router-dom";
import Playstore from "../../../../../assets/Images/playstore.png";
import Amazon from "../../../../../assets/Images/amazon.png";

function FooterTop() {
  const { pathname } = useLocation();
  // const navigate = useNavigate();

  // const gotToTop = (url) => {
  //   window.scrollTo(0, 0);
  //   navigate(url);
  // };

  return (
    <React.Fragment>
      <div className="Footer_top">
        {Footerdata.map((Footerdata) => {
          return (
            <div className="footer_block" key={Footerdata.id}>
              {Footerdata.companylogo && (
                <h4>
                  <Link to="/" title="CloudyFyTv - Digital Signage">
                    <img src={Footerdata.companylogo} alt="CloudyFy TV Logo" title="CloudyFyTv - Digital Signage" />
                  </Link>
                </h4>
              )}
              {Footerdata.content && <p>{Footerdata.content}</p>}
              {Footerdata.title && <h4>{Footerdata.title}</h4>}
              {Footerdata.findUsAddress && (
                <p>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                    <path d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 128a64 64 0 1 1 0 128 64 64 0 1 1 0-128z" />
                  </svg>
                  <Link to="https://www.google.com/maps/place/CloudyFy-TV/@23.0093125,72.5068041,15z/data=!4m6!3m5!1s0x395e9b278bd5fc37:0x6fb6f5bdda5987eb!8m2!3d23.0093125!4d72.5068041!16s%2Fg%2F11v9cg4pkz?entry=ttu" target="_blank">
                  {Footerdata.findUsAddress}
                  </Link>
                </p>
              )}
              {Footerdata.findUsMobile && (
                <a href="tel:7935127022" title={Footerdata.findUsMobile}>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                    <path d="M164.9 24.6c-7.7-18.6-28-28.5-47.4-23.2l-88 24C12.1 30.2 0 46 0 64C0 311.4 200.6 512 448 512c18 0 33.8-12.1 38.6-29.5l24-88c5.3-19.4-4.6-39.7-23.2-47.4l-96-40c-16.3-6.8-35.2-2.1-46.3 11.6L304.7 368C234.3 334.7 177.3 277.7 144 207.3L193.3 167c13.7-11.2 18.4-30 11.6-46.3l-40-96z" />
                  </svg>
                  {Footerdata.findUsMobile}
                </a>
              )}
              {Footerdata.findUsEmail && (
                <a href="mailto:sales@cloudyfytv.com" title={Footerdata.findUsEmail}>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                    <path d="M48 64C21.5 64 0 85.5 0 112c0 15.1 7.1 29.3 19.2 38.4L236.8 313.6c11.4 8.5 27 8.5 38.4 0L492.8 150.4c12.1-9.1 19.2-23.3 19.2-38.4c0-26.5-21.5-48-48-48H48zM0 176V384c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V176L294.4 339.2c-22.8 17.1-54 17.1-76.8 0L0 176z" />
                  </svg>
                  {Footerdata.findUsEmail}
                </a>
              )}
              {Footerdata.menu && (
                <ul>
                  {Footerdata.menu.map((item) => {
                    return (
                      <li
                        key={item.id}
                      // onClick={() => {
                      //   gotToTop(item.url);
                      // }}
                      >
                        {/* <span
                          className={pathname === item.url ? "active" : ""}
                          onClick={() => {
                            gotToTop(item.url);
                          }}
                        >
                          {item.name}
                        </span> */}

                        <Link
                          className={pathname === item.url ? "active" : ""}
                          to={item.url}
                          title={item.name}
                        >
                          {item.name}
                        </Link>
                      </li>
                    );
                  })}
                  <div className="downloadLinks">

                    <Link to="https://www.amazon.com/gp/product/B0CJ5K4CLT" target="_blank" title="Amazon Appstore">

                      <img src={Amazon} alt="Amazon" title="Amazon Appstore" style={{ marginRight: "10px" }} />
                    </Link>
                    <Link to="https://play.google.com/store/apps/details?id=com.tv.cloudyfy" target="_blank" title="Google Playstore">

                      <img src={Playstore} alt="Playstore" title="Google Playstore" />
                    </Link>
                  </div>
                </ul>
              )}


              {Footerdata.socail && (
                <ul>
                  {Footerdata.socail.map((item) => {
                    return (
                      <li key={item.id}>
                        <Link to={item.url} target="_blank" title={item.name}>
                          {item.icon} {item.name}
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              )}
            </div>
          );
        })}
      </div>
    </React.Fragment>
  );
}

export default FooterTop;
