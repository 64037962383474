import { ReactComponent as WifiImage } from "../../../../assets/Images/wifi.svg";
import { ReactComponent as LandscapeImage } from "../../../../assets/Images/landscape.svg";
import { ReactComponent as PlayImage } from "../../../../assets/Images/play.svg";
import { ReactComponent as AdaptationImage } from "../../../../assets/Images/adaptation.svg";
import { ReactComponent as ContentImage } from "../../../../assets/Images/content.svg";
import { ReactComponent as MirrorImage } from "../../../../assets/Images/mirror.svg";

import { ReactComponent as BulkScreensManagementImage } from "../../../../assets/Images/BulkScreensManagement.svg";
import { ReactComponent as LiveBroadcastImage } from "../../../../assets/Images/LiveBroadcast.svg";
import { ReactComponent as AdvancedSchedulingImage } from "../../../../assets/Images/AdvancedScheduling.svg";
import { ReactComponent as CustomPermissionsImage } from "../../../../assets/Images/CustomPermissions.svg";

import workoffline from "../../../../assets/Images/workoffline.png";
import lanscape from "../../../../assets/Images/lanscape.png";
import ProofofPlay from "../../../../assets/Images/ProofofPlay.png";
import usereusecontent from "../../../../assets/Images/usereusecontent.png";
import contentadaption from "../../../../assets/Images/contentadaption.png";

import Mirror from "../../../../assets/Images/Mirror.png";
import BulkScreen from "../../../../assets/Images/BulkScreen.png";
import LiveBroadcast from "../../../../assets/Images/LiveBroadcast.png";
import AdvanceSchedule from "../../../../assets/Images/AdvanceSchedule.png";
import CustomPermission from "../../../../assets/Images/CustomPermission.png";

export const Featuresdata = [
  {
    id: 1,
    image: workoffline,
    icon: <WifiImage />,
    title: "01",
    heading: "Works Offline",
    descreption:
      "<p>Its advanced technology ensures that your custom digital signage keeps playing offline till your license is active, and content is downloaded.</p>",
    // "listdata": "<li>We actually connect brands with people, and people with brands.</li><li>we develop content-rich digital signage solutions that help brand communicate better with their audienc  es.</li><li> It improves efficiency and reduces the operating cost.</li>",
    button: "Price Table",
    imagealt: "Offline work concept image",
    imagetitle: "Illustration representing the concept of working offline",
  },
  {
    id: 2,
    image: lanscape,
    icon: <LandscapeImage />,
    title: "02",
    heading: "Portrait or Landscape",
    descreption:
      "<p>You can get the best-fit view depending on the device and screen size. Our digital signage solution has two modes- portrait and landscape.</p>",
    // "listdata": "",
    button: "Price Table",
    imagealt: "Landscape view illustration",
    imagetitle: "Scenic landscape image",
  },
  {
    id: 3,
    image: ProofofPlay,
    icon: <PlayImage />,
    title: "03",
    heading: "Proof of Play",
    descreption:
      "<p>This feature ensures that all the displays across billboards and devices are connected and exhibit the contents in real-time. </p>",
    // "listdata": "<li>We actually connect brands with people, and people with brands.</li><li>we develop content-rich digital signage solutions that help brand communicate better with their audienc  es.</li><li> It improves efficiency and reduces the operating cost.</li>",
    button: "Price Table",
    imagealt: "Proof of Play illustration",
    imagetitle: "Visual representation of Proof of Play concept",
  },
  {
    id: 4,
    image: usereusecontent,
    icon: <ContentImage />,
    title: "04",
    heading: "Use and Reuse Content",
    descreption:
      "<p>Want to modify the content for your digital signage remotely? We make it possible with the Content Use and Reuse feature.</p>",
    // "listdata": "<li>We actually connect brands with people, and people with brands.</li><li>we develop content-rich digital signage solutions that help brand communicate better with their audienc  es.</li><li> It improves efficiency and reduces the operating cost.</li>",
    button: "Price Table",
    imagealt: "User Reuse Content illustration",
    imagetitle: "Visual depiction of the concept of users reusing content",
  },
  {
    id: 5,
    image: contentadaption,
    icon: <AdaptationImage />,
    title: "05",
    heading: "Content Adaptation",
    descreption:
      "<p>Our dynamic digital signage (DDS) can adapt the content effectively and display information that changes in line with a predetermined condition. </p>",
    // "listdata": "<li>We actually connect brands with people, and people with brands.</li><li>we develop content-rich digital signage solutions that help brand communicate better with their audienc  es.</li><li> It improves efficiency and reduces the operating cost.</li>",
    button: "Price Table",
    imagealt: "Content Adaptation illustration",
    imagetitle: "Visual representation of content adaptation concept",
  },
  {
    id: 6,
    image: Mirror,
    icon: <MirrorImage />,
    title: "06",
    heading: "Mirror Screen",
    descreption:
      "<p>It is useful if devices are flipped because of either power cable positioning or placement. In both portrait and landscape modes, this feature supports flipped version.</p>",
    // "listdata": "<li>We actually connect brands with people, and people with brands.</li><li>we develop content-rich digital signage solutions that help brand communicate better with their audienc  es.</li><li> It improves efficiency and reduces the operating cost.</li>",
    button: "Price Table",
    imagealt: "Mirror reflection illustration",
    imagetitle: "Visual depiction of a mirror concept",
  },
  {
    id: 7,
    image: BulkScreen,
    icon: <BulkScreensManagementImage />,
    title: "07",
    heading: "Bulk Screens Management",
    descreption:
      "<p>Users can publish updates and actions on all screens together thanks to the bulk screens management feature of <a href='https://www.cloudyfytv.com'>CloudyfyTV</a>. </p>",
    // "listdata": "<li>We actually connect brands with people, and people with brands.</li><li>we develop content-rich digital signage solutions that help brand communicate better with their audienc  es.</li><li> It improves efficiency and reduces the operating cost.</li>",
    button: "Price Table",
    imagealt: "Bulk Screen illustration",
    imagetitle: "Visual representation of bulk screen concept",
  },
  {
    id: 8,
    image: LiveBroadcast,
    icon: <LiveBroadcastImage />,
    title: "08",
    heading: "Live Broadcast",
    descreption:
      "<p>The sole objective of the Live Broadcast feature in digital signage is to provide infotainment (information and entertainment) for your target audience.</p>",
    // "listdata": "<li>We actually connect brands with people, and people with brands.</li><li>we develop content-rich digital signage solutions that help brand communicate better with their audienc  es.</li><li> It improves efficiency and reduces the operating cost.</li>",
    button: "Price Table",
    imagealt: "Live Broadcast illustration",
    imagetitle: "Visual representation of a live broadcast concept",
  },
  {
    id: 9,
    image: AdvanceSchedule,
    icon: <AdvancedSchedulingImage />,
    title: "09",
    heading: "Advanced Scheduling",
    descreption:
      "<p>This feature lets you instantly play your content on specific dates, times, and days. You can schedule its broadcast in advance to save time and even repeat the selected content.</p>",
    // "listdata": "<li>We actually connect brands with people, and people with brands.</li><li>we develop content-rich digital signage solutions that help brand communicate better with their audienc  es.</li><li> It improves efficiency and reduces the operating cost.</li>",
    button: "Price Table",
    imagealt: "Advanced Schedule illustration",
    imagetitle: "Visual representation of an advanced scheduling concept",
  },
  {
    id: 10,
    image: CustomPermission,
    icon: <CustomPermissionsImage />,
    title: "10",
    heading: "Custom Permissions",
    descreption:
      "<p>You can manage custom roles and permissions using this feature. Default permissions are also available for inviting users.</p>",
    // "listdata": "<li>We actually connect brands with people, and people with brands.</li><li>we develop content-rich digital signage solutions that help brand communicate better with their audienc  es.</li><li> It improves efficiency and reduces the operating cost.</li>",
    button: "Price Table",
    imagealt: "Custom Permission illustration",
    imagetitle: "Visual representation of custom permission concept",
  },
];

export default Featuresdata;
