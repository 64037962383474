import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "slick-carousel/slick/slick.css";

import 'antd/dist/antd.min.css';

import { HelmetProvider } from "react-helmet-async";

import FooterView from "./views/layouts/Footer/FooterView";
import HeaderView from "./views/layouts/Header/HeaderView";

import Home from "./views/front_pages/HomePage";

const About = React.lazy(() => import("./views/Pages/About"));
const Features = React.lazy(() => import("./views/Pages/Features"));
const WhyCloudyfyTV = React.lazy(() => import("./views/Pages/WhyCloudyfyTV"));
const Hardware = React.lazy(() => import("./views/Pages/Hardware"));
const Industries = React.lazy(() => import("./views/Pages/Industries"));

const Contact = React.lazy(() => import("./views/Pages/Contact"));
const Faq = React.lazy(() => import("./views/Pages/Faq"));
const ScrollToTop = React.lazy(() => import("./views/layouts/ScrollToTop"));
const PrivacyPolicy = React.lazy(() => import("./views/Pages/Privacypolicy"));
const TermsConditions = React.lazy(() => import("./views/Pages/termsconditions"));

const HotelRestaurant = React.lazy(() => import("./views/Pages/HotelRestaurant"));
const Corporate = React.lazy(() => import("./views/Pages/Corporate"));
const Retail = React.lazy(() => import("./views/Pages/Retail"));
const Entertainment = React.lazy(() => import("./views/Pages/Entertainment"));
const ShoppingMall = React.lazy(() => import("./views/Pages/ShoppingMall"));
const Education = React.lazy(() => import("./views/Pages/Education"));
const Finance = React.lazy(() => import("./views/Pages/Finance"));
const Healthcare = React.lazy(() => import("./views/Pages/Healthcare"));

const Transportation = React.lazy(() => import("./views/Pages/Transportation"));
const PageNotFound = React.lazy(() => import("./views/Pages/PageNotFound"));
const SpecialOffer = React.lazy(() => import("./views/Pages/SpecialOffer"));
const Pricing = React.lazy(() => import("./views/Pages/Pricing"));
const BlogDetails = React.lazy(() => import("./views/front_pages/BlogPage/Components/BlogDetails"));
const Blog = React.lazy(() => import("./views/front_pages/BlogPage/index"));
const CreatedAccount = React.lazy(() => import("./views/Pages/CreatedAccount/Index"));

function App() {

  return (
    <React.Fragment>
      <BrowserRouter>
        <HelmetProvider>
          <HeaderView />
          <ScrollToTop />
          <Routes>
            {/* <Route path="/">
            <Home setCurrent={setCurrent} />
          </Route> */}
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/faq" element={<Faq />} />
            <Route path="/features" element={<Features />} />
            <Route path="/Hardware" element={<Hardware />} />
            <Route path="/industries" element={<Industries />} />
            <Route path="/why-cloudyfy-tv" element={<WhyCloudyfyTV />} />
            {/* <Route path="/why-cloudyfy-tv" element={<WhyCloudyfyTV />} /> */}
            <Route path="/Contact" element={<Contact />} />
            <Route path="/terms-conditions" element={<TermsConditions />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/hotel-restaurant" element={<HotelRestaurant />} />
            <Route path="/Corporate" element={<Corporate />} />
            <Route path="/Finance" element={<Finance />} />
            <Route path="/Entertainment" element={<Entertainment />} />
            <Route path="/Retail" element={<Retail />} />
            <Route path="/ShoppingMall" element={<ShoppingMall />} />
            <Route path="/Transportation" element={<Transportation />} />
            <Route path="/Education" element={<Education />} />
            <Route path="/Healthcare" element={<Healthcare />} />
            <Route path="/special-offer" element={<SpecialOffer />} />
            <Route path="/pricing" element={<Pricing />} />
            <Route path="/blog" element={<Blog />} />
            <Route path="/blog/:id" element={<BlogDetails />} />
            <Route path="/create-account" element={<CreatedAccount />} />
            <Route path="*" element={<PageNotFound />} />
          </Routes>
          <FooterView />
        </HelmetProvider>
      </BrowserRouter>
    </React.Fragment>
  );
}

export default App;
