import React from "react";
import Form from "./Components/Form";

function ContactPage() {
  return (
    <section id="targetDiv" className="section contact_section">
      <div className="container">
        <h2>
          <span></span>Contact Us
        </h2>
        <Form />
      </div>
    </section>
  );
}

export default ContactPage;
