import React from 'react'
import { Link } from 'react-router-dom'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {  } from '@fortawesome/fontawesome-free-solid'
import ScrollAnimation from 'react-animate-on-scroll';

function Aboutleft() {
  return (

    <div className='section about_content'>
    <ScrollAnimation delay={1}
    duration={1} animateIn="fadeInLeft" animateOnce={true}>
      <h2><span></span>About Us</h2>
      <p>At CloudyfyTV, we have a knack for creating content-rich and visually appealing digital signage solutions that help your brand communicate more effectively with the target audience. We provide you with a centralized control system that can revolutionize the end user’s experience by managing the entire network of digital screens. </p>
      <p>CloudyfyTV is a name synonymous with elegant and expressive digital signage software that can play media from a cloud-based system. You can change the content from any geographical location with convenient and real-time control. </p>
      <div className='btn btn-border'>
        <Link to="/about" title='Read More'>Read More <FontAwesomeIcon icon="fa-solid fa-chevron-right" /></Link>
        
      </div>
      </ScrollAnimation>
     </div>

  )
}

export default Aboutleft