import React, { useState, useEffect } from "react";
import { blogData } from "./Components/BlogData";
import { Col, Row } from "antd";
import { Link, useLocation, useNavigate } from "react-router-dom";
import InnerBanner from "../../Pages/CommonSection/CommonInner/InnerBanner";
import SEOHeader from "../../../components/SEOHeader";

const Blog = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [totalData, setTotalData] = useState(3);
  const pathname = location.pathname;

  const getDetails = (slug) => {
    navigate(`/blog/${slug}`);
  };

  const pageMetatag = {
    title: "Expert's Guide on Digital Signage Solution | CloudyFY TV",
    description:
      "Discover cutting-edge insights and trends in Digital Signage with CloudyFy TV blog. Stay ahead with our expert insights, industry news, and innovative solutions.",
    keyword: "custom digital signage",
  };

  useEffect(() => {
    if (pathname === "/blog") {
      document.body.classList = "blog";
      setTotalData(6);
    } else {
      setTotalData(3);
    }
  }, [pathname]);

  const viewMore = () => {
    if (pathname === "/blog") {
      setTotalData((prev) => Math.min(prev + 3, blogData.length));
    } else {
      navigate("/blog");
    }
  };

  const splitIntoDot = (data, length) => {
    if (data?.length <= length) {
      return data;
    }
    return data?.slice(0, length) + "...";
  };

  // Calculate newArray by reversing the data and slicing the required amount
  const newArray = blogData.slice().reverse().slice(0, totalData);

  return (
    <>
      {pathname === "/blog" && (
        <>
          <InnerBanner />
          <SEOHeader pageMetatag={pageMetatag} />
        </>
      )}

      <section className="blogMain">
        {pathname === "/blog" && (
          <div className="section">
            <h2>
              <span></span>New Blog
            </h2>
          </div>
        )}
        <div className="container">
          <Row gutter={30} className="blog_post_page">
            {newArray.map((item) => (
              <Col key={item.id} sm={24} md={12} lg={8}>
                <div className="thumb">
                  <Link to={`/blog/${item.slug}`} title={item.imagetitle}>
                    <img
                      src={item.image}
                      alt={item?.imagealt}
                      title={item.imagetitle}
                      className="blogImg"
                    />
                  </Link>
                </div>
                <div className="blogContent">
                  <div
                    className="blogDate"
                    dangerouslySetInnerHTML={{ __html: item.date }}
                  ></div>
                  <div className="blogInnerContent">
                    <h3 onClick={() => getDetails(item.slug)}>
                      {splitIntoDot(item.title, 60)}
                    </h3>
                    {item.expert && <p>{splitIntoDot(item.expert, 60)}</p>}
                    <Link
                      className="readmore"
                      title="Read More"
                      to={`/blog/${item.slug}`}
                    >
                      Read More &gt;
                    </Link>
                  </div>
                </div>
              </Col>
            ))}
          </Row>
          {(pathname === "/" || pathname === "/blog") &&
            totalData < blogData.length && (
              <div className="btn btn-border Features_button blog_viewMore">
                <span onClick={viewMore}>
                  View All
                  <svg
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fas"
                    data-icon="chevron-right"
                    className="svg-inline--fa fa-chevron-right "
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 320 512"
                  >
                    <path
                      fill="currentColor"
                      d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z"
                    ></path>
                  </svg>
                </span>
              </div>
            )}
        </div>
      </section>
    </>
  );
};

export default Blog;
