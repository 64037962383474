// import menubannerslider from "../../../../assets/Images/menubannerslider.png";

import Corporate from "../../../../assets/Images/Corporate.jpg";
import Education from "../../../../assets/Images/Education.jpg";
import Entertainment from "../../../../assets/Images/Entertainment.jpg";
import Finance from "../../../../assets/Images/Finance.jpg";
import healthcare from "../../../../assets/Images/healthcare.jpg";
import HotelRestaurant from "../../../../assets/Images/HotelRestaurant.jpg";


export const Menubannerdata = [
  {
    id: 1,
    image: Corporate,
    title: "Corporate",
    imagetitle: "Corporate communication through digital signage displays",
    imagealt: "Corporate Digital Signage",
  },
  {
    id: 2,
    image: Education,
    title: "Education",
    imagetitle: "Empowering Education with Digital Signage",
    imagealt: "Digital Signage in Education",
  },
  {
    id: 3,
    image: Entertainment,
    title: "Entertainment",
    imagetitle: "Vibrant digital displays for entertainment",
    imagealt: "Digital entertainment signage",
  },
  {
    id: 4,
    image: Finance,
    title: "Finance",
    imagetitle: "Financial Insights on Digital Signage Display",
    imagealt: "Digital Signage in Finance",
  },
  {
    id: 5,
    image: healthcare,
    title: "healthcare",
    imagealt: "Digital Signage in Healthcare",
    imagetitle: "Digital Signage Enhancing Healthcare Communication",
  },
  {
    id: 6,
    image: HotelRestaurant,
    title: "HotelRestaurant",
    imagetitle: "Modern dining with digital displays",
    imagealt: "Digital Signage in Restaurants",
  }
]

export default Menubannerdata