import React from "react";
import whyes from "../../../../assets/Images/whyes.png";
import ScrollAnimation from 'react-animate-on-scroll';



function Whyesimage() {
  return (
    <div className="whyimage_side">
    <ScrollAnimation delay={3}
    duration={1} animateIn="fadeInLeft" animateOnce={true}>
    <div className="whyimage_image_part">
      <div className="whyimage">
        <img src={whyes} alt="Benefits of Digital Signage" title="Top benefits of Digital Signage in marketing Activities of Companies" />
      </div>
      </div>
      </ScrollAnimation>
    </div>
  );
}

export default Whyesimage;
