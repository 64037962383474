import React from 'react'
import { Link } from 'react-router-dom'
import Cloudyfy from "../../../../assets/Images/Cloudyfy.svg"

function Logo() {
  
  return (
    <div className='logo'>
    <Link to="/" title='CloudyFyTv - Digital Signage'>
        <img src={Cloudyfy} alt='CloudyFy TV Logo' title='CloudyFyTv - Digital Signage' />
        </Link>
    </div>
  )
}

export default Logo