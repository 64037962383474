import React from "react";
import Whyescontent from "./Components/Whyescontent";
import Whyesimage from "./Components/Whyesimage";

function whyespage() {
  return (
    <section className="section whyes_section">
      <div className="container">
        <Whyesimage />
        <Whyescontent />
      </div>
    </section>
  );
}

export default whyespage;
