import React from "react";
import { NavLink } from "react-router-dom";
import { menuItems } from "./menuItems";

function Sidebar(props) {
  const navClick = () => {
    props.setIsopen(false);
    document.body.classList.remove(["menu_open"]);
  };
  return (
    <ul className="menus">
      {menuItems.map((menu, index) => {
        return (
          <li className="menu-items" key={index}>
            <NavLink
              to={menu.url}
              exact={true}
              activeClassName="active"
              onClick={navClick}
            >
              {menu.title}
            </NavLink>
          </li>
        );
      })}
    </ul>
  );
}

export default Sidebar;
