import Slider_one from "../../../../assets/Images/Slider_one.jpg";
import Slider_two from "../../../../assets/Images/Slider_two.jpg";
import Slider_three from "../../../../assets/Images/Slider_three.png";
import Slider_four from "../../../../assets/Images/Slider_four.jpg";
import Slider_five from "../../../../assets/Images/Slider_five.png";
import Slider_six from "../../../../assets/Images/Slider_six.jpg";

import Corporate from "../../../../assets/Images/Corporate.jpg";
import healthcare from "../../../../assets/Images/healthcare.jpg";
import Transportation from "../../../../assets/Images/Transportation.jpg";

export const Productsdata = [
  {
    id: 1,
    img: Slider_one,
    title: "Hotel Restaurant",
    button: "Hotel Restaurant",
    url: "/hotel-restaurant",
    imagealt: "Digital signage for hotel marketing",
    imagetitle: "Strategic visual marketing through Digital signage"
  },
  {
    id: 2,
    img: Slider_two,
    title: "Education",
    button: "Education",
    url: "/education",
    imagealt: "Digital Signage in Higher Education",
    imagetitle: "Enhancing Higher Education with Digital Signage"
  },
  {
    id: 3,
    img: Slider_three,
    title: "Entertainment",
    button: "Entertainment",
    url: "/entertainment",
    imagealt: "Digital display solutions",
    imagetitle: "Next-level visual communication through digital displays"
  },
  {
    id: 4,
    img: Slider_four,
    title: "Finance",
    button: "Finance",
    url: "/finance",
    imagealt: "Digital Signage for Banks",
    imagetitle: "Digital signage transform banking experiences"
  },
  {
    id: 5,
    img: Slider_five,
    title: "Shopping Mall",
    button: "Shopping Mall",
    url: "/shoppingmall",
    imagealt: "Unlocking Digital Signage Advantages",
    imagetitle: "Explore the multiple benefits of digital signage"
  },
  {
    id: 6,
    img: Slider_six,
    title: "Retail",
    button: "Retail",
    url: "/retail",
    imagealt: "Interactive Signage Solutions",
    imagetitle: "Modern Digital Signage Enhancing Visual Communication"
  },
  {
    id: 7,
    img: healthcare,
    title: "Healthcare",
    button: "Healthcare",
    url: "/healthcare",
    imagetitle: "Digital Signage in Healthcare",
    imagealt: "Digital Signage Enhancing Healthcare Communication"
  },
  {
    id: 8,
    img: Transportation,
    title: "Transportation",
    button: "Transportation",
    url: "/transportation",
    imagealt: "Digital Signage in Transportation",
    imagetitle: "Efficient and dynamic transportation updates on digital screens"
  },
  {
    id: 9,
    img: Corporate,
    title: "Corporate Solutions",
    button: "Corporate Solutions",
    url: "/corporate",
    imagealt: "Corporate Digital Signage",
    imagetitle: "Corporate communication through digital signage displays"
  },
];

export default Productsdata;
