import { useEffect } from "react";
import { Helmet } from "react-helmet-async";

const SEOHeader = (props) => {
  const { title, description, keyword } = props.pageMetatag;

  
  const defaultMetatag = {
    title: "CloudyFY TV- Your Digital Signage Software & Solutions",
    description:
      "We are showcasing digital signage solutions cloudyFy TV at Dubai World Trade Centre during GITEX Global 2023 event. Let’s meet!",
    keyword: "digital signage software, Digital signage solutions",
  };

  useEffect(() => {
    const canonicalLink = document.createElement('link');
    canonicalLink.rel = 'canonical';
    canonicalLink.href = window.location.href;
    document.head.appendChild(canonicalLink);

    return () => {
      document.head.removeChild(canonicalLink);
    };
  }, []);


  return (
    <Helmet prioritizeSeoTags>
      <title>{title ?? defaultMetatag.title}</title>
      <meta name="description" content={description ?? defaultMetatag.description} />
      <meta name="keyword" content={keyword ?? defaultMetatag.keyword} />
      {/* <link rel="canonical" href={window.location.href} /> */}
    </Helmet>
  );
};

export default SEOHeader;
