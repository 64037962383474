import Axios from "axios";
import React from "react";

// var isValid = (email) => {
//   return email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
// };

class EmailForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email_id: "",
      isValid: false,
      isSubmitted: false,
      errorMsg: "",
      succeMsg: "",
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    // this.renderMessage = this.renderMessage.bind(this);
  }

  handleChange(event) {
    this.setState({
      email_id: event.target.value,
    });
  }

  handleSubmit(event) {
    event.preventDefault();
    // this.setState({
    //   isSubmitted: true,
    // });
    return Axios.create({
      baseURL: process.env.REACT_APP_API_URL,
    }).post(`/api/newslatter`, {
      email_id: this.state.email_id,
    })
      .then(({ data }) => {
        this.setState({ succeMsg: data, email_id: "" });
        setTimeout(() => {
          // After 3 seconds set the show value to false
          this.setState({ succeMsg: "", email_id: "" });
        }, 3000);
        document.getElementById("newsleterresetform").reset();
        return data;
      })
      .catch(({ response: { data } }) => {
        this.setState({ errorMsg: data?.email_id[0], email_id: "" });
        setTimeout(() => {
          // After 3 seconds set the show value to false
          this.setState({ errorMsg: "", email_id: "" });
        }, 3000);
        document.getElementById("newsleterresetform").reset();
      });
  }

  render() {
    return (
      <React.Fragment>
        <h3>
          Subscribe to our <br /> <span>newsletter</span> & stay updated
          <span>!</span>
          <p>* We Will Not Spam You</p>
        </h3>
        <form onSubmit={this.handleSubmit} id="newsleterresetform">
          <div className="input_box">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
              <path d="M64 112c-8.8 0-16 7.2-16 16v22.1L220.5 291.7c20.7 17 50.4 17 71.1 0L464 150.1V128c0-8.8-7.2-16-16-16H64zM48 212.2V384c0 8.8 7.2 16 16 16H448c8.8 0 16-7.2 16-16V212.2L322 328.8c-38.4 31.5-93.7 31.5-132 0L48 212.2zM0 128C0 92.7 28.7 64 64 64H448c35.3 0 64 28.7 64 64V384c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V128z" />
            </svg>
            <input
              placeholder="Enter your email"
              type="text"
              value={this.state.email}
              onChange={this.handleChange}
              name="email_id"
            />
          </div>
          {this.state.succeMsg.data ? (
            <p className="success">{this.state.succeMsg.message}</p>
          ) : (
            <p className="error">{this.state.errorMsg}</p>
          )}

          <button type="submit" value="isSubmitted" title="Subscribe Now">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
              <path d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z" />
            </svg>
            Subscribe Now
          </button>
        </form>
      </React.Fragment>
    );
  }
}

export default EmailForm;
