import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import video_img from "../../../../assets/Images/video_img.png";
import Videoplay from "./Video/Videoplay";

function Aboutright() {
  return (
    <div className="about_img">
      <ScrollAnimation delay={1} duration={1} animateIn="fadeInRight" animateOnce={true}>
        <span>
          <img src={video_img} alt="Digital Signage Software " title="Digital Signage Software" />
        </span>
        <Videoplay />
      </ScrollAnimation>
    </div>
  );
}

export default Aboutright;
