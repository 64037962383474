import React from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { } from "@fortawesome/fontawesome-free-solid";
import { Link } from 'react-router-dom';


function Scheduledemo() {

  const [active, setActive] = React.useState();
  const openBookModal = () => {
    setActive(!active)
    document.body.classList.add('modal-open')
  }
  const closeBookModal = () => {
    setActive(!active)
    document.body.classList.remove('modal-open')
  }
  return (
    <div className='schedule_button btn btn-black'>
      <Link to={"/create-account"} className="primary_button createAccBtn" title='Start Your 7 Days Free Trial'>
        Start Your 7 Days Trial<FontAwesomeIcon icon="fa-solid fa-chevron-right" />
      </Link>
      <button className="primary_button" onClick={() => openBookModal()} title='Request a Demo'>
        Request a Demo<FontAwesomeIcon icon="fa-solid fa-chevron-right" />
      </button>


      <div className={`modal schedule_popup ${active ? "active" : ""}`}>
        <button className="closed" onClick={closeBookModal}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M310.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L160 210.7 54.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L114.7 256 9.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 301.3 265.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L205.3 256 310.6 150.6z" /></svg>
        </button>
        <iframe src="https://calendly.com/silicon-it-hub/schedule-a-call" title="CloudyFy Tv"></iframe>

      </div>
    </div>
  )
}

export default Scheduledemo