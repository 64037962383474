import React, { Component } from "react";
import About from "../AboutPage";
import ContactPage from "../Contact";
import Feature from "../FeaturesPage";
import Hardware from "../HardwarePage";
import Industries from "../IndustriesPage";
import Solution from "../SolutionPage";
import WhyessPage from "../WhyessPage";
import BlogPage from "../BlogPage"

import SimpleSlider from "./Components/SimpleSlider";
import SEOHeader from "../../../components/SEOHeader";

class Home extends Component {
  render() {
    const pageMetatag = {
      title: "CloudyFY TV- Your Digital Signage Software & Solutions",
      description:
        "Transform your business with CloudyFyTV's top-tier digital signage software & solutions. Engage, inform, & captivate your audience with our advanced technology.",
      keyword: "digital signage software, Digital signage solutions",
      
    };
    return (
      <React.Fragment>
        <SEOHeader pageMetatag={pageMetatag} />
        <section className="section home_section">
          <div className="scroll">
            <span>Scroll</span>
          </div>
          <SimpleSlider />
        </section>
        <Industries />
        <About />
        <Feature />
        <Solution />
        <Hardware />
        <WhyessPage />
        <BlogPage />
        <ContactPage />
      </React.Fragment>
    );
  }
}

export default Home;
