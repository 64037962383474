import Cloudyfy from "../../../../../assets/Images/Cloudyfy.svg";

import { ReactComponent as FacebookImage } from "../../../../../assets/Images/Facebook.svg";
import { ReactComponent as TwitterImage } from "../../../../../assets/Images/twitter.svg";
import { ReactComponent as LinkedInImage } from "../../../../../assets/Images/LinkedIn.svg";
import { ReactComponent as PinterestImage } from "../../../../../assets/Images/Pinterest.svg";

export const Footerdata = [
  {
    id: 1,
    companylogo: Cloudyfy,
    content:
      "At Cloudyfy Tv, we develop content-rich digital signage solutions that help brand communicate better with their.",
  },
  {
    id: 2,
    title: "Find US",
    findUsAddress:
      "B1-Ground Floor, Safal Profitaire, Corporate Rd, Prahlad Nagar, Ahmedabad, Gujarat 380015",
    findUsMobile: "+91 79 3512 7022",
    findUsEmail: " sales@cloudyfytv.com",
  },
  {
    id: 3,
    title: "QUICK LINKS",
    menu: [
      {
        id: 1,
        name: "Home",
        url: "/",
      },
      {
        id: 2,
        name: "Why CloudyFy Tv?",
        url: "/why-cloudyfy-tv",
        
      },
      {
        id: 3,
        name: "Features",
        url: "/features",
      },
      {
        id: 4,
        name: "Hardware",
        url: "/hardware",
      },
      {
        id: 5,
        name: "Blog",
        url: "/blog",
      },
      {
        id: 6,
        name: "About",
        url: "/about",
      },
      {
        id: 7,
        name: "Faq",
        url: "/faq",
      },
      // {
      //   id: 6,
      //   name: "Solutions",
      //   url: "/solutions",
      // },
      {
        id: 7,
        name: "Industries",
        url: "/industries",
      },
      // {
      //   id: 8,
      //   name: "Pricing",
      //   url: "/pricing",
      // },
      // {
      //   id: 9,
      //   name: "Special Offer",
      //   url: "/special-offer",
      // },
    ],
  },
  {
    id: 4,
    title: "Follow uS",
    socail: [
      {
        id: 1,
        name: "Facebook",
        url: "https://www.facebook.com/CloudyFyTV",
        icon: <FacebookImage />,
      },
      {
        id: 2,
        name: "Twitter",
        url: "https://twitter.com/CloudyfyT",
        icon: <TwitterImage />,
      },
      {
        id: 3,
        name: "LinkedIn",
        url: "https://www.linkedin.com/company/cloudyfytv",
        icon: <LinkedInImage />,
      },
      {
        id: 4,
        name: "pinterest",
        url: "https://in.pinterest.com/cloudfytv/",
        icon: <PinterestImage />,
      },
    ],
  },
];
