import React from "react";
import FeaturesData from "./Featuresdata";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { } from "@fortawesome/fontawesome-free-solid";
import ScrollAnimation from "react-animate-on-scroll";

function Features() {
  const onlyFiveData = FeaturesData.filter((item) => item.id < 6)

  return (
    <React.Fragment>
      {onlyFiveData.map((Featuresdata) => {
        return (

          <div key={Featuresdata.id} className="feature_content">
            <ScrollAnimation delay={1} duration={1} animateIn="fadeInRight"  animateOnce={true}>
              <div className="feature_content_inner">
                <div className="icon-ttile">
                  <span className="icon">
                    {Featuresdata.icon}
                  </span>
                  <span className="title">{Featuresdata.title}</span>
                </div>
                <h3>{Featuresdata.heading}</h3>
                <div
                  dangerouslySetInnerHTML={{
                    __html: Featuresdata.descreption,
                  }}
                ></div>
              </div>
            </ScrollAnimation>
          </div>
        );
      })}

      <div className="btn btn-border Features_button">
        <Link to="/features" title="View All">
          View All <FontAwesomeIcon icon="fa-solid fa-chevron-right" />
        </Link>
      </div>
    </React.Fragment>
  );
}

export default Features;
