import React, { useEffect, useRef, useState } from "react";

import { ReactComponent as UserImages } from "../../../../assets/Images/user.svg";
import { ReactComponent as PhonenoImages } from "../../../../assets/Images/phoneno.svg";
import { ReactComponent as MailImages } from "../../../../assets/Images/mail.svg";
import { ReactComponent as CompanyImages } from "../../../../assets/Images/Company.svg";
import { ReactComponent as SubjectImages } from "../../../../assets/Images/Subject.svg";
import { ReactComponent as ResetImages } from "../../../../assets/Images/Reset.svg";

import Axios from "axios";

const Form = () => {
  const [values, setValues] = React.useState({
    fullname: "",
    email: "",
    mobile: "",
    message: "",
    company_name: "",
    subject: "",
  });

  const [validations, setValidations] = React.useState({
    fullname: "",
    email: "",
    mobile: "",
    message: "",
    company_name: "",
    subject: "",
  });

  const [showMsg, setShowMsg] = useState();
  const [captchavalues, setCaptchavalues] = useState();
  const [captchaValidations, setCaptchaValidations] = useState();
  const [disabledbtn, setDisabledbtn] = useState(true);

  const [showError, setShowError] = React.useState();

  const validateOne = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    let message = "";

    if (!value && name !== "company_name" && name !== "mobile") {
      message = `It is required`;
    }

    if (value && name === "email" && !/\S+@\S+\.\S+/.test(value)) {
      message = "Email format must be as example@mail.com";
    }

    if (value && name === "mobile") {
      if (value.length < 4) {
        message = "Please enter min 4 characters!";
      } else if (value.length > 20) {
        message = "Please enter max 20 characters!";
      }
    }

    setValidations({ ...validations, [name]: message });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  const captchaHandleChange = (e) => {
    const { name, value } = e.target;
    if (name && name === "re_captcha") {
      if (captchavalues !== value) {
        setCaptchaValidations("CAPTCHA does not match.");
        setDisabledbtn(true);
      } else {
        setCaptchaValidations();
        setDisabledbtn(false);
      }
    }
  };

  const [showDiv, setShowDiv] = useState(false);
  const inputRef = useRef();

  const randomnumbersubstring = (length) => {
    let result = "";
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
      inputRef.current.value = "";
      if (inputRef.current.value === "") {
        setDisabledbtn(true);
      }
    }
    return result;
  };

  useEffect(() => {
    setCaptchavalues(randomnumbersubstring(4));
    const timer = setTimeout(() => {
      setShowDiv(true);
      setShowMsg();
    }, 5000);

    return () => {
      clearTimeout(timer);
    };
  }, [showMsg]);

  const handleSubmit = (e) => {
    e.preventDefault();

    return Axios.create({
      baseURL: process.env.REACT_APP_API_URL,
    })
      .post(`/api/contact-us`, values)
      .then(({ data }) => {
        setShowMsg(data.message);
        setShowError();
        setValues({
          fullname: "",
          email: "",
          mobile: "",
          company_name: "",
          subject: "",
          message: "",
        });

        document.getElementById("contactForm").reset();
        return data;
      })
      .catch(({ response: { data } }) => {
        const testDummy = [];
        testDummy.push(data);
        setShowError(testDummy[0].STATUS); 
      });
  };
  const { fullname, email, mobile, message, company_name, subject } = values;
  return (
    <form onSubmit={handleSubmit} id="contactForm">
      <div className="input_block">
        <input
          type="text"
          placeholder="Full Name*"
          name="fullname"
          value={fullname}
          onChange={handleChange}
          onBlur={validateOne}
          
        />
        <p>
          {showError?.fullname &&showError.fullname[0]}{!showError?.fullname&&validations.fullname}
        </p>
        <UserImages />
      </div>
      <div className="input_block">
        <input
          type="number"
          placeholder="Phone No."
          name="mobile"
          value={mobile}
          onChange={handleChange}
          onBlur={validateOne}
        />
        <p>
          {showError?.mobile && showError.mobile[0]} {!showError?.mobile&&validations.mobile}
        </p>
        <PhonenoImages />
      </div>
      <div className="input_block">
        <input
          type="email"
          placeholder="Email*"
          name="email"
          value={email}
          onChange={handleChange}
          onBlur={validateOne}
          
        />
        <p>
          {showError?.email && showError.email[0]}{!showError?.email&&validations.email}
        </p>
        <MailImages />
      </div>

      <div className="input_block">
        <input
          type="text"
          placeholder="Subject*"
          name="subject"
          value={subject}
          onChange={handleChange}
          onBlur={validateOne}
          
        />
        <p>
          {showError?.subject && showError.subject[0]}{!showError?.subject&&validations.subject}
        </p>
        <SubjectImages />
      </div>

      <div className="input_block">
        <input
          type="text"
          placeholder="Company Name"
          name="company_name"
          value={company_name}
          onChange={handleChange}
          onBlur={validateOne}
        />
        <p>
         {showError?.company_name && showError.company_name[0]} {!showError?.company_name&&validations.company_name}
        </p>
        <CompanyImages />
      </div>

      <div className="input_block textarea">
        <textarea
          placeholder="Message*"
          name="message"
          value={message}
          onChange={handleChange}
          onBlur={validateOne}
        />
        <p>
         {showError?.message && showError.message[0]} {!showError?.message&&validations.message}
        </p>
      </div>

      <div className="input_block">
        <input
          type="reset"
          placeholder="Captcha verification"
          name="captcha"
          value={captchavalues}
        />
        <ResetImages
          onClick={() => setCaptchavalues(randomnumbersubstring(4))}
        />
      </div>

      <div className="input_block">
        <input
          type="text"
          placeholder="CAPTCHA"
          name="re_captcha"
          onChange={captchaHandleChange}
          ref={inputRef}
          onBlur={validateOne}
        />
        <p>
          {disabledbtn && captchaValidations && captchaValidations}
        </p>
      </div>

      <div className="btn btn-black input_block">
        <button type="submit" disabled={disabledbtn} className="button">
          <span>Submit</span>
        </button>
      </div>

      {showDiv && showMsg && <div className="success_message">{showMsg}</div>}
    </form>
  );
};

export default Form;
