import React from "react";
import { Link } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {} from "@fortawesome/fontawesome-free-solid";
import ScrollAnimation from "react-animate-on-scroll";

function Whyescontent() {
  return (
    <div className="content_side">
      <ScrollAnimation
        delay={3}
        animateIn="fadeInRight"
        duration={1}
        animateOnce={true}
      >
        <h2>
          <span></span>Why CloudyFy TV?
        </h2>
        <p>
          We provide our corporate clients with cost-effective and reliable
          digital signage solutions that easily adapt to their business
          requirements and various screens across devices like Tablets, Android
          TVs, and iPads.
        </p>
        <div className="btn btn-black">
          <Link to="/why-cloudyfy-tv" title="Read More">
            Read More <FontAwesomeIcon icon="fa-solid fa-chevron-right" />
          </Link>
        </div>
      </ScrollAnimation>
    </div>
  );
}

export default Whyescontent;
