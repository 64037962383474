import React from "react";
import EmailForm from "./Components/EmailForm";


function News() {
  return (
        <div className="email_form_section" id="emailform">
            <div className="form">
            <EmailForm />
            </div>
        </div>
  );
}

export default News;
