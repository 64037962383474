import React from "react";

import aboutbanner from "../../../../assets/Images/aboutbanner.png";

import SpecialBanner from "../../../../assets/Images/SpecialBanner.png";

import restaurant from "../../../../assets/Images/restaurant.png";
import Industries from "../../../../assets/Images/Industries.png";
import christopher from "../../../../assets/Images/christopher.png";
import Hardware from "../../../../assets/Images/Hardware.png";
import contact from "../../../../assets/Images/contact.png";
import faqbanner from "../../../../assets/Images/faqbanner.png";

import PrivacyPolicy from "../../../../assets/Images/PrivacyPolicy.png";
import TermsConditions from "../../../../assets/Images/TermsConditions.png";
import WhyCloudyfyTV from "../../../../assets/Images/WhyCloudyfyTV.png";

import HotelRestaurantbanner from "../../../../assets/Images/HotelRestaurantbanner.png";
import Corporatebanner from "../../../../assets/Images/Corporatebanner.png";
import Retailbanner from "../../../../assets/Images/Retailbanner.png";
import Entertainmentbanner from "../../../../assets/Images/Entertainmentbanner.png";
import ShoppingMallbanner from "../../../../assets/Images/ShoppingMallbanner.png";
import Transportationbanner from "../../../../assets/Images/Transportationbanner.png";
import Educationbanner from "../../../../assets/Images/Educationbanner.png";
import Financebanner from "../../../../assets/Images/Financebanner.png";
import Healthcarebanner from "../../../../assets/Images/Healthcarebanner.png";
import BlogBanner from "../../../../assets/Images/blogBanner1.png";

function InnerBanner() {
  const title =
    window.location.href.split("/")[3] === "about"
      ? "About Us"
      : window.location.href.split("/")[3] === "solutions"
      ? "Solutions"
      : window.location.href.split("/")[3] === "industries"
      ? "Our Scope"
      : window.location.href.split("/")[3] === "features"
      ? "Features"
      : window.location.href.split("/")[3] === "hardware"
      ? "Hardware"
      : window.location.href.split("/")[3] === "contact"
      ? "Contact Us"
      : window.location.href.split("/")[3] === "why-cloudyfy-tv"
      ? "Why CloudyfyTV?"
      : window.location.href.split("/")[3] === "terms-conditions"
      ? "Terms Conditions"
      : window.location.href.split("/")[3] === "privacy-policy"
      ? "Privacy Policy"
      : window.location.href.split("/")[3] === "hotel-restaurant"
      ? "Hotel Restaurant"
      : window.location.href.split("/")[3] === "corporate"
      ? "Corporate"
      : window.location.href.split("/")[3] === "retail"
      ? "Retail"
      : window.location.href.split("/")[3] === "entertainment"
      ? "Entertainment"
      : window.location.href.split("/")[3] === "shoppingmall"
      ? "Shopping Mall"
      : window.location.href.split("/")[3] === "transportation"
      ? "Transportation"
      : window.location.href.split("/")[3] === "education"
      ? "Education"
      : window.location.href.split("/")[3] === "finance"
      ? "Finance"
      : window.location.href.split("/")[3] === "healthcare"
      ? "Healthcare"
      : window.location.href.split("/")[3] === "subscription"
      ? "Subscription"
      : window.location.href.split("/")[3] === "special-offer"
      ? "Registration"
      : window.location.href.split("/")[3] === "pricing"
      ? "Pricing"
      : window.location.href.split("/")[3] === "faq"
      ? "FAQ"
      : window.location.href.split("/").length === 5 &&
        window.location.href.split("/")[3] === "blog"
      ? ""
      : window.location.href.split("/")[3] === "blog"
      ? "Our Blog"
      : "";

  const bannerImg =
    window.location.href.split("/")[3] === "about"
      ? aboutbanner
      : window.location.href.split("/")[3] === "solutions"
      ? restaurant
      : window.location.href.split("/")[3] === "industries"
      ? Industries
      : window.location.href.split("/")[3] === "features"
      ? christopher
      : window.location.href.split("/")[3] === "hardware"
      ? Hardware
      : window.location.href.split("/")[3] === "contact"
      ? contact
      : window.location.href.split("/")[3] === "why-cloudyfy-tv"
      ? WhyCloudyfyTV
      : window.location.href.split("/")[3] === "terms-conditions"
      ? TermsConditions
      : window.location.href.split("/")[3] === "privacy-policy"
      ? PrivacyPolicy
      : window.location.href.split("/")[3] === "hotel-restaurant"
      ? HotelRestaurantbanner
      : window.location.href.split("/")[3] === "corporate"
      ? Corporatebanner
      : window.location.href.split("/")[3] === "retail"
      ? Retailbanner
      : window.location.href.split("/")[3] === "entertainment"
      ? Entertainmentbanner
      : window.location.href.split("/")[3] === "shoppingmall"
      ? ShoppingMallbanner
      : window.location.href.split("/")[3] === "transportation"
      ? Transportationbanner
      : window.location.href.split("/")[3] === "education"
      ? Educationbanner
      : window.location.href.split("/")[3] === "finance"
      ? Financebanner
      : window.location.href.split("/")[3] === "healthcare"
      ? Healthcarebanner
      : window.location.href.split("/")[3] === "subscription"
      ? WhyCloudyfyTV
      : window.location.href.split("/")[3] === "special-offer"
      ? SpecialBanner
      : window.location.href.split("/")[3] === "pricing"
      ? contact
      : window.location.href.split("/")[3] === "faq"
      ? faqbanner
      : window.location.href.split("/")[3] === "blog"
      ? BlogBanner
      : "";

  return (
    <section
      className="inner_page_banner"
      style={{
        backgroundImage: `url(${bannerImg})`,
      }}
    >
      {title && (
        <div className="page_heading">
          <h1>{title}</h1>
        </div>
      )}
    </section>
  );
}

export default InnerBanner;
