import React from "react";
import Hardwareslider from "./Components/Hardwareslider";

function Hardware() {
  return (
    <section className="section hardware_section">
      <div className="container">
        <h2>
          <span></span>How to Use Hardware
        </h2>
        <p>
          CloudyFy TV is one of the best cloud-based digital signage solutions
          that supports all the major devices including Android TV and Apple TV.{" "}
        </p>
      </div>
      <Hardwareslider />
    </section>
  );
}

export default Hardware;
