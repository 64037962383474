import React from "react";
import Aboutleft from "./Components/Aboutleft";
import Aboutright from "./Components/Aboutright";

function About() {
  return (
    <section className="about-section">
      <div className="container">
        <Aboutleft />
        <Aboutright />
      </div>
    </section>
  );
}

export default About;
