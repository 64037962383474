import React from "react";
import Features from "./Components/Features";
import ScrollAnimation from "react-animate-on-scroll";

function Feature() {
  return (
    <section className="section feature-section">
      <div className="container">
        <div className="feature_content">
          <ScrollAnimation delay={1} duration={1} animateIn="fadeInLeft" animateOnce={true}>
            <div className="feature_content_inner">
              <h2>
                <span></span>Top Features of Our Digital Signage Solution</h2>
              <p>Our digital signage solutions are designed to enable you to manage the content across all the screens easily. Whether a single screen or a thousand screens, you can always get complete control from anywhere in the world.</p></div>
          </ScrollAnimation>
        </div>
        <Features />
      </div>
    </section>
  );
}

export default Feature;
