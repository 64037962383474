import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {} from "@fortawesome/fontawesome-free-solid";

import { slidesData } from "./slidesData";

function Hardwareslider() {
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  const [slider1, setSlider1] = useState(null);
  const [slider2, setSlider2] = useState(null);

  useEffect(() => {
    setNav1(slider1);
    setNav2(slider2);
  }, [slider1, slider2]);

  const settingsMain = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    dots: false,
    fade: true,
    asNavFor: ".slider-nav",
  };

  const settingsThumbs = {
    slidesToShow: 6,
    slidesToScroll: 1,
    asNavFor: ".slider-for",
    dots: false,
    arrows: false,
    swipeToSlide: true,
    centerMode: true,
    focusOnSelect: true,
    infinite: true,

    responsive: [
      {
        breakpoint: 1366,
        settings: {
          slidesToShow: 6,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 5,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 550,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 375,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <React.Fragment>
      <Slider
        {...settingsMain}
        asNavFor={nav2}
        ref={(slider) => setSlider1(slider)}
      >
        {slidesData.map((slide) => (
          <div className="slick-slide" key={slide.id}>
            <div className="slick_main_content">
              <div className="hardware_img">
                <img
                  src={slide.image}
                  alt={slide?.imagealt}
                  title={slide?.imagetitle}
                />
              </div>

              <div className="content">
                <h3>{slide.title}</h3>
                <div
                  className="slick-slide-label"
                  dangerouslySetInnerHTML={{ __html: slide.label }}
                ></div>
                <div className="btn btn-black">
                  <Link
                    to="/hardware"
                    // onClick={() => {
                    //   setCurrent(slide.id);
                    // }}
                    title={slide.button}
                  >
                    {slide.button}{" "}
                    <FontAwesomeIcon icon="fa-solid fa-chevron-right" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        ))}
      </Slider>
      <div className="thumbnail-slider-wrap">
        <Slider
          {...settingsThumbs}
          asNavFor={nav1}
          ref={(slider) => setSlider2(slider)}
        >
          {slidesData.map((slide) => (
            <div className="slick-slide" key={slide.id}>
              <div className="thumb_image">
                <img
                  className="slick-slide-image"
                  src={slide.image}
                  alt={slide.title}
                  title={slide?.imagetitle}
                />
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </React.Fragment>
  );
}

export default Hardwareslider;
