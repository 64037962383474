import React from "react";
import Solutionslider from "./Components/Slidersolution";

function Solution() {
  return (
    <section className="section solution_section">
      <div className="container">
        <h2>
          <span></span>Unique Digital Signage Solution
        </h2>
        <p>
          Our tailored digital signage software can bring value to your business
          and give it the advantage of interactive advertising.{" "}
        </p>
      </div>
      <div className="slider_main">
        <Solutionslider />
      </div>
    </section>
  );
}

export default Solution;
