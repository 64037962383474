import React from 'react'
import News from '../../front_pages/Newsleter'
import Copyright from './Components/Copyright'
import FooterTop from './Components/Footermenu/FooterTop'
import { useLocation } from 'react-router-dom'

function FooterView() {
  const location = useLocation()
  return location.pathname !== "/create-account" ? (

    <footer>
      <News />
      <div className='container'>
        <FooterTop />
        <Copyright />
      </div>
    </footer>
  )
    : null
}

export default FooterView