import bannerone from '../../../../assets/Images/bannerone.png';
import bannertwo from '../../../../assets/Images/bannertwo.png';
import bannerthree from '../../../../assets/Images/bannerthree.png';

export const Featuresdata = [
  {
    id: 1,
    image: bannerthree,
    title: "",
    heading: "Unlocking the Value of Your Business Across Screens",
    content: "CloudyfyTV offers a digital platform to attract more people toward your business with smart signage.",
    buttonprimary: "Read More",
    buttonsecondary: "Contact Us",
    imagealt:
      "Business",
    imagetitle: "Digital Business Insights",
    
  },
  {
    id: 2,
    image: bannertwo,
    title: "Give Your Business a Boost of Visual",
    heading: "Richness",
    content: "Empowering your business with powerful and feature-rich digital signage software to attract and engage customers.",
    buttonprimary: "Read More",
    buttonsecondary: "Contact Us",
    imagealt:
      "Business Digitally",
    imagetitle: "Innovative business strategies on digital display",
  },
  {
    id: 3,
    image: bannerone,
    title: "Strengthen Your Footprints with Excellent Visual",
    heading: "Experience",
    content: "CloudyFy TV, your reliable and robust digital signage solution, gives your customers and employees a visually rich experience.",
    buttonprimary: "Read More",
    buttonsecondary: "Contact Us",
    imagealt:
      "Benefits of Digital Signage",
    imagetitle: "Top benefits of Digital Signage in marketing Activities of Companies",
  },
]

export default Featuresdata