import React from "react";
import Slider from "react-slick";

import MenubannerData from "./Menubannerdata";

export default function Menubanner() {
  var settings = {
    dots: true,
    arrows: false,
    autoplay: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    focusOnSelect: true,

    responsive: [
      {
        breakpoint: 767,
        settings: {
          dots: false,
        },
      },
    ],
  };
  return (
    <div className="menu_slider">
      <Slider {...settings}>
        {MenubannerData.map((Menubannerdata) => {
          return (
            <React.Fragment key={Menubannerdata.id}>
              <div className="banner_img">
                <img
                  src={Menubannerdata.image}
                  alt={Menubannerdata.imagealt}
                  title={Menubannerdata.imagetitle}
                />
              </div>
            </React.Fragment>
          );
        })}
      </Slider>
    </div>
  );
}
