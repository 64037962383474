
import Smart from "../../../../assets/Images/Smart.png";

// import back from "../../../../assets/Images/back.png";
// import side from "../../../../assets/Images/side.png";
// import front from "../../../../assets/Images/front.png";

import Apple from "../../../../assets/Images/Apple.png";
import ipad from "../../../../assets/Images/ipad.png";
import Android from "../../../../assets/Images/Android.png";
// import GoogleChromecast from "../../../../assets/Images/GoogleChromecast.png";
import Stick from "../../../../assets/Images/Stick.png";
import LED from "../../../../assets/Images/LED.png";
// import Tablet from "../../../../assets/Images/Tablet.png";


export const slidesData = [
  {
    id: 1,
    image: Smart,
    title: "LED Google Chromecast",
    imagealt: "LED Google Chromecast",
    imagetitle: "CloudyFy TV Supports LED Google Chromecast",
    label:
      "<p>It is a steaming media adapter or a dongle developed by Google that enables users to play online content including videos and music on a digital television.<p>",
    button: "Read More",
    aboutcontent:
      "<li>Streaming media adapter/dongle developed by Google</li><li>Enables users to play online content on a digital television</li><li>Control playback using a mobile device or personal computer</li><li>Mobile and web apps use the Google Cast protocol</li><li>Commands can also be issued via Google Assistant</li><li>Later models introduced an interactive user interface</li><li>Remote control is available</li>"
    },
  {
    id: 2,
    image: Apple,
    imagealt: "Apple TV",
    imagetitle: "CloudyFy TV Supports Apple TV",
    title: "Apple TV",
    label: "<p>Apple Inc. has developed a line of media player software known as the Apple TV app. It is useful for viewing television shows and films to consumer electronic devices. </p>",
    button: "Read More",
    aboutcontent:
      "<li>Developed by Apple Inc.</li><li>Line of media player software known as the Apple TV app</li><li>Used for viewing television shows and films on consumer electronic devices</li><li>Small network appliance hardware</li><li>Sends received media data (video and audio) to a television set or external display</li><li><strong>Media services include:</strong></li> <ol><li>Streaming media</li><li>TV Everywhere-based services</li><li>Local media sources</li><li>Sports journalism and broadcasts</li></ol>",
  },
  {
    id: 3,
    image: ipad,
    imagealt: "Tablets",
    imagetitle: "CloudyFy TV Supports Tablets",
    title: "Tablets",
    label: "<p>Tablet is a lightweight device to browse the Internet, send and receive emails, play games, watch TV and do other activities. It is like a mini laptop.</p>",
    button: "Read More",
    aboutcontent:
      "<li>A Lightweight device</li><li>Used for browsing the Internet, sending/receiving emails, playing games, etc.</li><li>Resembles a mini laptop</li><li>Mobile OS</li><li>Touchscreen display</li><li>Processing circuitry</li><li>Rechargeable battery</li><li>Similar capabilities to computers</li><li>Lacks some input/output (I/O) abilities of other devices</li>",
  },
  {
    id: 4,
    image: Android,
    imagealt: "Smart TV",
    imagetitle: "CloudyFy TV Supports Smart TV",
    title: "Smart TV",
    label:
      "<p>A Smart TV referred as connected TV or hybrid TV. It is a technological convergence between flat screen television, set-top box, and computer. It comes with apps that offers features of smartphone, computer and tablets.</p>",
    button: "Read More",
    aboutcontent:
      "<li>Also known as connected TV or hybrid TV</li><li>Flat-screen television</li><li>Set-top box</li><li>Computer</li><li>Comes with apps similar to smartphones and computers</li><li>Capable of connecting to the internet</li><h4>Inbuilt apps include</h4><ul><li>Twitter</li><li>Facebook</li><li>YouTube</li></ul><h4>Accessible using</h4><ul><li>Wireless keyboard</li><li>Trackpad or mouse</li></ul>",
  },
  
  {
    id: 5,
    image: Stick,
    imagealt: "Amazon Firestick",
    imagetitle: "CloudyFy TV Supports Amazon Firestick",
    title: "Amazon Firestick",
    label: "<p>A media streaming device made by Amazon enables users to stream video, install apps, and play music on their televisions. Amazon Firestick can convert a normal TV to a smart TV.</p>",
    button: "Read More",
    aboutcontent:
      "<li>Media streaming device by Amazon</li><li>Enables users to:</li><li>Stream video</li><li>Install apps</li><li>Play music on their televisions</li><li>Converts a normal TV to a smart TV</li><li>HDMI stick requiring an HDMI port on the television</li><li>Functions like a set-top box</li><li>Allows streaming videos and playing games on a normal TV with an HDMI port</li><li>Useful for displaying content across different media using the Internet</li>",
  },
  {
    id: 6,
    image: LED,
    imagealt: "Android TV",
    imagetitle: "CloudyFy TV Supports Android TV",
    title: "Android TV",
    label: "<p>Android TV is a smart TV OS (Operating System) developed by Google for television sets, set-top boxes, soundbars, and digital media players. It is smarter than a normal TV.</p>",
    button: "Read More",
    aboutcontent:
      "<li>Smart TV operating system (OS) developed by Google</li><li>Designed for use in:</li><li>Television sets</li><li>Set-top boxes</li><li>Soundbars</li><li>Digital media players</li><li>Smarter than a normal TV</li><li>Successor to Google TV</li><h4>Features:</h4><ol><li>User interface focused on content discovery</li><li>Voice search capability</li><li>Content aggregation from various media apps and services</li><li>Integration with recent Google technologies:</li><li>Assistant</li><li>Cast</li><li>Knowledge Graph</li></ol>",
  },
  
];


export default slidesData