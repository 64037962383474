import React, { Component } from 'react'
import { Link } from 'react-router-dom'
export class Copyright extends Component {

    render() {
        return (
            <div className='copyright'>

                <div className='left'>
                    <p>© Copyright 2023 CloudyFy Tv All Rights Reserved</p>
                </div>

                {window.location.href.split("/")[3] !== "create-account" && <div className='copyright_menu'>
                    <ul>
                        <li>
                            <Link to="/terms-conditions" title=' Terms & Conditions'> Terms & Conditions</Link>
                        </li>
                        <li>
                            <Link to="/privacy-policy" title='Privacy Policy'>Privacy Policy</Link>
                        </li>
                    </ul>
                </div>
                }

            </div>
        )
    }
}

export default Copyright