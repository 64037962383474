import React, { useEffect } from "react";
import Logo from "./Components/Logo";
import Nav from "./Components/Nav";

const HeaderView = () => {
  useEffect(() => {
    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  });

  const isSticky = (e) => {
    const header = document.querySelector(".header-section");
    const scrollTop = window.scrollY;
    scrollTop >= 109
      ? header.classList.add("is-sticky")
      : header.classList.remove("is-sticky");
  };

  return (
    <header className="header-section">
      <Logo />
      <Nav />
    </header>
  );
};

export default HeaderView;
