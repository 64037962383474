import React, { useState } from "react";
import { Link } from "react-router-dom";
import Menubanner from "./Menubanner";
import Sidebar from "./Sidebar";
import Scheduledemo from "./Scheduledemo";

const Nav = () => {
  const [isOpen, setIsopen] = useState(false);

  const ToggleSidebar = () => {
    isOpen === true ? setIsopen(false) : setIsopen(true);
  };

  return (
    <React.Fragment>
      <nav className="navigation">
        <Scheduledemo />
        <div
          className="hamburger"
          onClick={() => {
            ToggleSidebar(true);
            document.body.classList.add(["menu_open"]);
          }}
        >
          <button className="toggle-button"></button>
        </div>
        <div className={`sidebar ${isOpen === true ? "open" : ""}`}>
          <React.Fragment>
            <button
              className="closed_button"
              onClick={() => {
                ToggleSidebar(true);
                document.body.classList.remove(["menu_open"]);
              }}
            ></button>
            <div className="menu_open">
              <Sidebar setIsopen={setIsopen} />
              <Menubanner />
              <div className="header_bottom">
                <div className="left_side">
                  <p>© Copyright 2023 Cloudyfy Tv. All Rights Reserved</p>
                </div>
                <div className="right_side">
                  <div className="contact_details">
                    <ul>
                      <li>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 512 512"
                        >
                          <path d="M164.9 24.6c-7.7-18.6-28-28.5-47.4-23.2l-88 24C12.1 30.2 0 46 0 64C0 311.4 200.6 512 448 512c18 0 33.8-12.1 38.6-29.5l24-88c5.3-19.4-4.6-39.7-23.2-47.4l-96-40c-16.3-6.8-35.2-2.1-46.3 11.6L304.7 368C234.3 334.7 177.3 277.7 144 207.3L193.3 167c13.7-11.2 18.4-30 11.6-46.3l-40-96z" />
                        </svg>
                        <a href="tel:7935127022">+91 79 3512 7022</a>
                      </li>
                      <li>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 512 512"
                        >
                          <path d="M48 64C21.5 64 0 85.5 0 112c0 15.1 7.1 29.3 19.2 38.4L236.8 313.6c11.4 8.5 27 8.5 38.4 0L492.8 150.4c12.1-9.1 19.2-23.3 19.2-38.4c0-26.5-21.5-48-48-48H48zM0 176V384c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V176L294.4 339.2c-22.8 17.1-54 17.1-76.8 0L0 176z" />
                        </svg>
                        <a href="mailto:sales@cloudyfytv.com">
                          sales@cloudyfytv.com
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="social">
                    <ul>
                      <li>
                        <Link
                          to="https://www.facebook.com/CloudyFyTV"
                          target="_blank"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 320 512"
                          >
                            <path d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z" />
                          </svg>
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="https://twitter.com/CloudyfyT"
                          target="_blank"
                        >
                          <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z"/></svg>
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="https://www.linkedin.com/company/cloudyfytv"
                          target="_blank"
                        >
                          <svg
                            viewBox="0 0 72 72"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g fill="none">
                              <path d="M62,62 L51.315625,62 L51.315625,43.8021149 C51.315625,38.8127542 49.4197917,36.0245323 45.4707031,36.0245323 C41.1746094,36.0245323 38.9300781,38.9261103 38.9300781,43.8021149 L38.9300781,62 L28.6333333,62 L28.6333333,27.3333333 L38.9300781,27.3333333 L38.9300781,32.0029283 C38.9300781,32.0029283 42.0260417,26.2742151 49.3825521,26.2742151 C56.7356771,26.2742151 62,30.7644705 62,40.051212 L62,62 Z M16.349349,22.7940133 C12.8420573,22.7940133 10,19.9296567 10,16.3970067 C10,12.8643566 12.8420573,10 16.349349,10 C19.8566406,10 22.6970052,12.8643566 22.6970052,16.3970067 C22.6970052,19.9296567 19.8566406,22.7940133 16.349349,22.7940133 Z M11.0325521,62 L21.769401,62 L21.769401,27.3333333 L11.0325521,27.3333333 L11.0325521,62 Z"></path>
                            </g>
                          </svg>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </React.Fragment>
        </div>
      </nav>
    </React.Fragment>
  );
};

export default Nav;
