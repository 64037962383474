import React from "react";

import Corporate from "../../../../assets/Images/Corporate.jpg";
import Education from "../../../../assets/Images/Education.jpg";
import Entertainment from "../../../../assets/Images/Entertainment.jpg";
import Finance from "../../../../assets/Images/Slider_four.jpg";
import healthcare from "../../../../assets/Images/healthcare.jpg";
import HotelRestaurant from "../../../../assets/Images/HotelRestaurant.jpg";
import Retail from "../../../../assets/Images/Retail.jpg";
import ShoppingMall from "../../../../assets/Images/ShoppingMall.jpg";
import Transportation from "../../../../assets/Images/Transportation.jpg";

import { Link } from "react-router-dom";

function Photo() {
  const photoData = [
    {
      id: 1,
      img: Corporate,
      button: "Corporate Solutions",
      url: "corporate",
      imagetitle: "Corporate communication through digital signage displays",
      imagealt: "Corporate Digital Signage",
      photosub: [
        {
          id: 1,
          img: Education,
          button: "Education",
          imagetitle: "Empowering Education with Digital Signage",
          imagealt: "Digital Signage in Education",
          url: "education",
        },
      ],
    },
    {
      id: 2,
      img: Entertainment,
      button: "Entertainment",
      url: "entertainment",
      imagetitle: "Vibrant digital displays for entertainment",
      imagealt: "Digital entertainment signage",
      photosub: [
        {
          id: 1,
          img: Finance,
          button: "Finance",
          url: "finance",
          imagetitle: "Financial Insights on Digital Signage Display",
          imagealt: "Digital Signage in Finance",
        },
      ],
    },
    {
      id: 3,
      img: HotelRestaurant,
      button: "Hotel & Restaurant",
      url: "hotel-restaurant",
      imagetitle: "Modern dining with digital displays",
      imagealt: "Digital Signage in Restaurants",

      photosub: [
        {
          id: 1,
          img: Retail,
          button: "Retail",
          url: "retail",
          imagealt: "Digital Signage in Retail",
          imagetitle:
            "Enhance retailing experience through dynamic digital signage",
        },
      ],
    },
    {
      id: 4,
      img: healthcare,
      button: "Healthcare",
      url: "healthcare",
      imagealt: "Digital Signage in Healthcare",
      imagetitle: "Digital Signage Enhancing Healthcare Communication",

      photosub: [
        {
          id: 1,
          img: Transportation,
          button: "Transportation",
          url: "transportation",
          imagealt: "Digital Signage in Transportation",
          imagetitle:
            "Efficient and dynamic transportation updates on digital screens",
        },
        {
          id: 2,
          img: ShoppingMall,
          button: "Departmental Store & Shopping Mall",
          url: "shoppingmall",
          imagealt: "Shopping Mall Digital Signage ",
          imagetitle: "Digital Shopping Experience",
        },
      ],
    },
  ];
  return (
    <React.Fragment>
      {photoData.map((photoData) => (
        <div className="photos_block" key={photoData.id}>
          <div className="img_block">
            <Link to={photoData.url} title={photoData?.imagetitle}>
              <img src={photoData.img} alt={photoData?.imagealt} title={photoData?.imagetitle} />
              <span>{photoData.button}</span>
            </Link>
          </div>
          {photoData.photosub.map((data) => {
            return (
              <div className="img_block" key={data.id}>
                <Link to={data.url} title={data?.imagetitle}>
                  <img src={data.img} alt={data?.imagealt} title={data?.imagetitle} />
                  <span>{data.button}</span>
                </Link>
              </div>
            );
          })}
        </div>
      ))}
    </React.Fragment>
  );
}

export default Photo;
